// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/back.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.product-main {
    width: 100vw;
}

.col1 {
    background: #ECECEC;
}

.card-col-row {
    font-size: .8em;
    font-weight: 500;
}

.pagination-style {
    top: 90%;
    width: 20%
}

.pagination-style ul {
    display: flex;
    justify-content: flex-start !important
}

.pagination-style ul li {
    padding: 0 .8rem;
}

.relative {
    position: relative!important;
}

@media only screen and (max-width:450px) {
    .media-product {
        padding: 10px;
        height: max-content;
    }
    /* .media-product-button{
        margin: 5px;
        width: 150px;
    } */
}

.background {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}
.backCircle {
    background-color: rgba(255, 255, 255, 0.483);
   
}
/* scroll style history */
.scrollbar::-webkit-scrollbar-track
{
	background-color: #F5F5F5;
    border-radius:15px ;
}

.scrollbar::-webkit-scrollbar
{
	height: 3px;
	background-color: #F5F5F5;
    
}

.scrollbar::-webkit-scrollbar-thumb
{
	background-color: #b0adad;
	border-radius:15px ;
}
`, "",{"version":3,"sources":["webpack://./src/components/product/product.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,QAAQ;IACR;AACJ;;AAEA;IACI,aAAa;IACb;AACJ;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,4BAA4B;AAChC;;AAEA;IACI;QACI,aAAa;QACb,mBAAmB;IACvB;IACA;;;OAGG;AACP;;AAEA;IACI,yDAA8C;AAClD;AACA;IACI,4CAA4C;;AAEhD;AACA,yBAAyB;AACzB;;CAEC,yBAAyB;IACtB,mBAAmB;AACvB;;AAEA;;CAEC,WAAW;CACX,yBAAyB;;AAE1B;;AAEA;;CAEC,yBAAyB;CACzB,mBAAmB;AACpB","sourcesContent":[".product-main {\n    width: 100vw;\n}\n\n.col1 {\n    background: #ECECEC;\n}\n\n.card-col-row {\n    font-size: .8em;\n    font-weight: 500;\n}\n\n.pagination-style {\n    top: 90%;\n    width: 20%\n}\n\n.pagination-style ul {\n    display: flex;\n    justify-content: flex-start !important\n}\n\n.pagination-style ul li {\n    padding: 0 .8rem;\n}\n\n.relative {\n    position: relative!important;\n}\n\n@media only screen and (max-width:450px) {\n    .media-product {\n        padding: 10px;\n        height: max-content;\n    }\n    /* .media-product-button{\n        margin: 5px;\n        width: 150px;\n    } */\n}\n\n.background {\n    background-image: url(\"../../assets/back.jpg\");\n}\n.backCircle {\n    background-color: rgba(255, 255, 255, 0.483);\n   \n}\n/* scroll style history */\n.scrollbar::-webkit-scrollbar-track\n{\n\tbackground-color: #F5F5F5;\n    border-radius:15px ;\n}\n\n.scrollbar::-webkit-scrollbar\n{\n\theight: 3px;\n\tbackground-color: #F5F5F5;\n    \n}\n\n.scrollbar::-webkit-scrollbar-thumb\n{\n\tbackground-color: #b0adad;\n\tborder-radius:15px ;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
