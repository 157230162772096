// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.box {
    border: 2px solid #f4541f;
    background-color: #fff;
    color: #000;
}

.input {
    border: 2px solid #5882c169;
} 
.button {
  background-color: #B91E23;
}
.header-div {
    background-color: #FFEDE7;
}

.loader {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: block;
    margin: 8px auto;
    position: relative;
    color: #fff;
    box-sizing: border-box;
    animation: animloader 2s linear infinite;
  }
  
  @keyframes animloader {
    0% {
      box-shadow: 14px 0 0 -2px, 38px 0 0 -2px, -14px 0 0 -2px, -38px 0 0 -2px;
    }
    25% {
      box-shadow: 14px 0 0 -2px, 38px 0 0 -2px, -14px 0 0 -2px, -38px 0 0 2px;
    }
    50% {
      box-shadow: 14px 0 0 -2px, 38px 0 0 -2px, -14px 0 0 2px, -38px 0 0 -2px;
    }
    75% {
      box-shadow: 14px 0 0 2px, 38px 0 0 -2px, -14px 0 0 -2px, -38px 0 0 -2px;
    }
    100% {
      box-shadow: 14px 0 0 -2px, 38px 0 0 2px, -14px 0 0 -2px, -38px 0 0 -2px;
    }
  }`, "",{"version":3,"sources":["webpack://./src/components/login/login.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,sBAAsB;IACtB,WAAW;AACf;;AAEA;IACI,2BAA2B;AAC/B;AACA;EACE,yBAAyB;AAC3B;AACA;IACI,yBAAyB;AAC7B;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,cAAc;IACd,gBAAgB;IAChB,kBAAkB;IAClB,WAAW;IACX,sBAAsB;IACtB,wCAAwC;EAC1C;;EAEA;IACE;MACE,wEAAwE;IAC1E;IACA;MACE,uEAAuE;IACzE;IACA;MACE,uEAAuE;IACzE;IACA;MACE,uEAAuE;IACzE;IACA;MACE,uEAAuE;IACzE;EACF","sourcesContent":[".box {\n    border: 2px solid #f4541f;\n    background-color: #fff;\n    color: #000;\n}\n\n.input {\n    border: 2px solid #5882c169;\n} \n.button {\n  background-color: #B91E23;\n}\n.header-div {\n    background-color: #FFEDE7;\n}\n\n.loader {\n    width: 10px;\n    height: 10px;\n    border-radius: 50%;\n    display: block;\n    margin: 8px auto;\n    position: relative;\n    color: #fff;\n    box-sizing: border-box;\n    animation: animloader 2s linear infinite;\n  }\n  \n  @keyframes animloader {\n    0% {\n      box-shadow: 14px 0 0 -2px, 38px 0 0 -2px, -14px 0 0 -2px, -38px 0 0 -2px;\n    }\n    25% {\n      box-shadow: 14px 0 0 -2px, 38px 0 0 -2px, -14px 0 0 -2px, -38px 0 0 2px;\n    }\n    50% {\n      box-shadow: 14px 0 0 -2px, 38px 0 0 -2px, -14px 0 0 2px, -38px 0 0 -2px;\n    }\n    75% {\n      box-shadow: 14px 0 0 2px, 38px 0 0 -2px, -14px 0 0 -2px, -38px 0 0 -2px;\n    }\n    100% {\n      box-shadow: 14px 0 0 -2px, 38px 0 0 2px, -14px 0 0 -2px, -38px 0 0 -2px;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
