// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/back.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wer-main {
    width: 100%;
    
}

.background {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
}
.backCircle {
    background-color: rgba(255, 255, 255, 0.483);
}

.add-product-table, .add-product-table th, .add-product-table td {
    border: 1px solid rgba(193, 192, 192, 0.48);
    border-collapse: collapse;
    padding: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/components/werHouse/werHouse.css"],"names":[],"mappings":"AAAA;IACI,WAAW;;AAEf;;AAEA;IACI,yDAA8C;IAC9C,4BAA4B;IAC5B,sBAAsB;IACtB,2BAA2B;IAC3B,4BAA4B;AAChC;AACA;IACI,4CAA4C;AAChD;;AAEA;IACI,2CAA2C;IAC3C,yBAAyB;IACzB,aAAa;AACjB","sourcesContent":[".wer-main {\n    width: 100%;\n    \n}\n\n.background {\n    background-image: url(\"../../assets/back.jpg\");\n    background-repeat: no-repeat;\n    background-size: cover;\n    background-position: center;\n    background-attachment: fixed;\n}\n.backCircle {\n    background-color: rgba(255, 255, 255, 0.483);\n}\n\n.add-product-table, .add-product-table th, .add-product-table td {\n    border: 1px solid rgba(193, 192, 192, 0.48);\n    border-collapse: collapse;\n    padding: 1rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
