// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/back.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.history-bg {
    background-repeat: no-repeat;
    background-position: center;
    background-size: 90% 85%;
    width: 100%;
    min-height: 100vh;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}



.history-info-bg {
    background-color: #DEE6FF;
}

.background {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.backCircle {
    background-color: rgba(255, 255, 255, 0.683);
   
}`, "",{"version":3,"sources":["webpack://./src/components/history/history.css"],"names":[],"mappings":"AAAA;IACI,4BAA4B;IAC5B,2BAA2B;IAC3B,wBAAwB;IACxB,WAAW;IACX,iBAAiB;IACjB,SAAS;IACT,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,sBAAsB;AAC1B;;;;AAIA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yDAA8C;IAC9C,4BAA4B;IAC5B,2BAA2B;IAC3B,sBAAsB;AAC1B;AACA;IACI,4CAA4C;;AAEhD","sourcesContent":[".history-bg {\n    background-repeat: no-repeat;\n    background-position: center;\n    background-size: 90% 85%;\n    width: 100%;\n    min-height: 100vh;\n    margin: 0;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex-direction: column;\n}\n\n\n\n.history-info-bg {\n    background-color: #DEE6FF;\n}\n\n.background {\n    background-image: url(\"../../assets/back.jpg\");\n    background-repeat: no-repeat;\n    background-position: center;\n    background-size: cover;\n}\n.backCircle {\n    background-color: rgba(255, 255, 255, 0.683);\n   \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
