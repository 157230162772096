const translateRu = {
  home: "Дом",
  about: "О сайте",
  service: "Услуги",
  gallary: "Галерея",
  dashboard: "Панель приборов",
  produkt: "Проект",
  addproject: "Добавить в проект",
  project: "Продукт",
  werhouse: "Склад",
  client: "Клиенты",
  users: "Пользователи",
  wiew: "Деталь",
  cassier: "Кассир",
  photo: "Фото",
  owner: "Владелец",
  start: "Дата начала",
  end: "Дата окончания",
  selectWer: "Выбрать склад",
  comment: "Комментарий",
  productPhoto: "Фото продукта",
  projectId: "ID проекта",
  productName: "Наименование товара",
  warehouseName: "Название склада",
  productCount: "Количество продуктов",
  totalWeight: "Общий вес",
  totalKub: "Всего куб",
  histori: "История",
  homeText1: "Мы доставим ваши посылки",
  homeText01: "В любое место!",
  homeText2: "НАЙДИТЕ ЭТО | КУПИТЕ ЭТО | ОТПРАВЬТЕ ЭТО",
  homeText3: "Мы позволяем вам делать покупки по всему миру и ",
  homeText03: "отправлять в Логисиппо прямо к вашему порогу!",
  homeText4: "Доставьте свою ",
  homeText5: "Логистику",
  homeText6: "Безопасно и быстро",
  homeText7:
    "С самого начала до конца наша безупречная логистическая служба гарантирует успешный опыт доставки каждый раз. Начните прямо сейчас и увидите разницу сами.",
  homeText8: "Перевозки",
  homeText9: "и Логистика ",
  homeText09: "Услуги",
  homeText10: "ШАРАЙТ ↗️",
  homeText11: "Автомобильные перевозки",
  homeText12: "Судовые перевозки",
  homeText13: "Воздушные перевозки",
  homeText14: "Безупречная доставка",
  homeText014: "С нашими логистическими",
  homeText0014: "решениями.",
  homeText15:
    "Наша служба поддержки всегда доступна и готова помочь с любыми проблемами доставки, с обеспечением гладкого опыта.",
  homeText16: "Cсылки",
  homeText17: "Компания",
  homeText18: "О нас",
  homeText19: "Часто задаваемые вопросы",
  homeText20: "Связаться с нами",
  homeText21: "© 2023 ГСР Логистика",
  homeText22: "При поддержке: IT City Academy",
  circle1: "Законченный",
  circle2: "В ожидании",
  circle3: "Отмена",
  cardCurrent: "Текущая страница",
  card1: "Число",
  card2: "Положение дел",
  card3: "ЭТД",
  card4: "Продукт",
  card5: "Текущее местоположение",
  card6: "Измерение",
  card7: "Владелец",
  card8: "Итоговая цена",
  productAdd1: "Добавить продукт",
  productAdd2: "Транспорт",
  productSUser: "Выберите пользователя",
  productAdd3: "Имя проекта",
  productAdd34: "Комментарий",
  productAdd4: "Количество измерений",
  productAdd5: "Выберите транспорт",
  productAdd05: "Машина",
  productAdd050: "Самолет",
  productAdd005: "Тренироваться",
  productAdd6: "Измерение",
  productAdd60: "Выберите статус продукта",
  productAdd06: "Кг",
  productAdd060: "Кусок",
  productAdd006: "Куб",
  productAdd7: "Код ТН",
  productAdd76: "Цена",
  productAddKg: "Масса",
  productAddKub: "Куб   ",
  productAdd8: "Статус продукта",
  productAdd9: "Выберите cклад",
  productSearch: "🔍 Поиск...",
  productSearchIdnumber: "🔍 ИД Номер...",
  projectNameSearch: "🔍 Название проекта...",
  productNameSearch: "🔍 Наименование товара...",
  wereHouseNameSearch: "🔍 Название склада...",
  close: "Закрывать",
  add: "Добавить проект",
  selectCh: "Выберите",
  selectP: "Выберите продукт",
  kubkg: "Куб и кг",
  pricekubkg: "Цена Куб или кг",
  priceresult: "Результат цена",
  PriceOF: "Цена",
  resultPriceOf: "Результат цена",
  customPrice: "Индивидуальная цена",
  costChina: "Стоимость Китая",
  next: "Далее",
  enter: "Входить",
  enterPrice: "Введите цену",
  priceForRoad: "Цена за дорогу",
  select: "Выберите проект",
  selectProject: "Выберите поиск проекта",
  addd: "Добавить",
  more: "Посмотреть больше",
  addWer: "Добавить cклад",
  editWer: "Редакт cклад",
  addProduct: "Добавить продукт",
  success: "Успешно завершено!",
  error: "Что-то ошибка.",
  notFound: "Пользователь не найден",
  edit: "Редакт",
  all: "Все",
  date: "Дата",
  action: "Действие",
  save: "Сохранять",
  transport: "Транспорт",
  editProject: "Редактировать проект",
  editProduct: "Редактировать продукт",
  addClients: "Добавить пользователь",
  delete: "Удалить",
  file: "Файл",
  kg: "Кг",
  kub: "Куб",
  download: "Скачать",
  delete2: "Вы уверены, что?",
  logout: "Выйти",
  status: "Выберите статус продукта",
  status1: "В ожидании",
  status2: "Идущий",
  status3: "Отмена",
  status4: "Приехал",
  status5: "Завершенный",
  status6: "Взолнованный",
  notfound: "Не найдено",
  usernotfound: "Пользователь не найден",
  searchBy: "Искать по",
  dropdown1: "Код ТН продукта",
  dropdown2: "Статус продукта",
  dropdown3: "Имя продукта",
  dropdown4: "Имя пользователя",
  dropdown5: "Статус проекта",
  dropdown6: "Имя проекта",
  login1: "Вход",
  login2: "Номер телефона",
  login3: "Число", // bu placeholderdagi malumot
  login4: "Пароль",
  login5: "Введите пароль",
  weiw1: "Код ТН",
  weiw2: "Владелец",
  weiw3: "Созданный",
  weiw4: "Измерение",
  weiw5: "Измерение количества",
  weiw6: "Транспорт",
  weiw7: "Адрес",
  history1: "Дата начала",
  history2: "Дата окончания",
  history3: "Поиск по идентификатору пользователя",
  history4: "Поиск по идентификационному номеру",
  history5: "Детали",
  history6: "Число",
  history7: "Текущее местоположение",
  history8: "Положение дел",
  history9: "ЭТД",
  history10: "Владелец",
  history11: "Продукт",
  client1: "Номер",
  client2: "Имя",
  client3: "Номер телефона",
  client03: "Номер телефона пуст",
  client4: "Пароль",
  client04: "Пароль пуст",
  client5: "Все продукты",
  client6: "Завершенный",
  client7: "В ожидании",
  client8: "Отменить продукт",
  client9: "Выберите роль",
  client10: "Клиент",
  client11: "Менеджер",
  cassierData: "Добавить данные",
  cassierDatanextModal: "Подтвердить введенную информацию?",
  addclient1: "Редактировать пользователь",
  addclient2: "Новый пользователь",
  addclient3: "Имя пользователя",
  addclient4: "Введите имя",
  addclient5: "ID пользователя",
  addclient6: "Идентификационный номер",
  addclient7: "Номер телефона пользователя",
  addclient8: "Введите номер телефона",
  addclient9: "Пароль",
  addclient10: "Введите пароль",
  userName: "Имя пользователя",
  projectName: "Название проекта",
  // productName: "Наименование товара",
  productAdd7x: "Х",
  productAdd7y: "Й",
  productAdd7z: "З",
  price: "Цена",
  projectStatusEd: "Статус проекта успешно изменен",
  projectStatusEdErr: "Ошибка редактирования статуса проекта",
  sm: "см",
  measure: "Измерять",
  totalPrice: "Общая сумма",
  cct: "Ccт",
  costChines: "Cost China",
  totalpriceand: "Общий ценовой результат",
  notFoundFile: "Файл не найден",
  notFoundCashier: "Кассир не найден 😊",
  avia: "Авиа",
  auto: "Авто",
  jd: "ЖД",
  1: "Падгатовка",
  2: "Загрузка",
  3: "Отправка",
  4: "В пути в Китай",
  5: "В гроница в Китай",
  6: "В пути в транзитном зоне",
  7: "В граница узб",
  8: "В процесс разтаможка",
  9: "Готово",
  admin: "Админ",
  en: "ЕНГ",
  ru: "РУС",
  projectStatus: "Статус проекта",
  gotov: "Готово",
  products: "продукты",
  noStatus: "Нет состояния",
  statuss: "Статус",
  projectStatusEdit: "Изменить статус проекта",
  numberOfSeats: "Cколько",
  allProducts: "Все продукты",
  listWarning: "Пожалуйста, добавьте хотя бы один товар",
  cashierNotFoundd: "Информация о кассе доступна",
  projectCount: "Количество проектов",
  projectStatusUpdate: "Произошла ошибка при попытке изменить статус.",
  required: "необходимый",
  requiredWareHouseCanvas: "Необходимо указать название склада.",
  cashierSuccessfullySaved: "Данные успешно сохранены",
  cashierWarningNextBtn:
    "Все поля должны быть заполнены. Пожалуйста, проверьте информацию еще раз",
  imgNotFound: "Изображение не доступно",
  calculated: "Рассчитано",
  summaCct: "Сумма сст",
  summaChina: "Расход в китай",
  summaRastamojka: "Сумма растаможка",
  idNumber: "Идентификационный номер",
  kasserHisob: "Этот товар не был учтен кассиром",
  category: "Цена груза",
  priceCargo: "Объемный вес",
  priceKub: "Цена за куб",
  // Landing page
  home: "Китай находится с нами в одном шаге.",
  info: "Наша команда GSR Logistics уже 10 лет предоставляет качественный сервис клиентам.",
  yuklar: "Мы найдем необходимый Вам тип груза.",
  hujjat: "Помогаем с документами и процедурами продажи.",
  kafolat: "Ваши посылки прибудут полностью застрахованными",
  razmer: "Размер загрузки",
  ogirlik: "Масса",
  qayerga: "Oткуда",
  qayerdan: "Kуда",
  qancha: "Тип груза",
  m3: "м3",
  kg: "кг",
  qayer: "Йиwу",
  Toshkent: "Ташкент",
  mahsulot: "Домашние товары",
  xisoblash: "Расчет нагрузки",
  komponiya: "О нашей компании",
  mijozlar: "Победа наших клиентов для нас",
  muhim: "важно",
  xojakbar:"Ходжакбар",
  biznes:"Бизнес",
  Language:'Язык',
  taos:"Пожалуйста, заполните информацию!",
  ObunaBuling:"Подписаться",
  yukHajmi:"Объем груза 200 кг и около 3 кубических метров",
  boglanish: "Соединять",
  xizmat_turi: "Виды логистических услуг",
  yuk_davlati:"Тип логистики варьируется в зависимости от страны и расстояния доставки груза.",
  yuk_mashinasi: "Использование грузовика",
  xitoydan_yuk:"80% случаев привоза грузов из Китая приходится на фуры мы используем",
  cargo: "Грузовой воздух",
  avia_cargo:"Небольшие, очень быстро необходимые грузы через Avia Cargo доставленный.",
  temir_yul: "Железнодорожный",
  narxi_arzon: "Срок не важен, цена выбирается подешевле.",
  yutuqlarimiz:"Наши достижения",
  keltirilgan_yuklar: "Доставленный груз",
  natija:"Посмотрите на результаты, которых мы достигли за 10 лет",
  hamkorlik:"Мы сотрудничаем уже более 4 лет",
  keltirilgan_butun_yuklar: "Общий доставленный груз (груз/контейнер)",
  kun: "день",
  muddat: "Среднее время доступа",
  mijoz_fikri: "Что думают наши клиенты",
  gsrlogistik: "регулярных клиентов GSR Logistics",
  ishonch: "доверие к нашей компании – это наше достижение.",
  sifatli_xizmat: "“Качественное обслуживание и скорость”",
  xitoydan_tashqari:"Компания GSR Logistics помимо нахождения в Китае на каждом этапе до прибытия в Ташкент всегда думает о нашей выгоде, поэтому мы верим",
  erkin: "Арикулов Эркин",
  detail: "SHVEY DETAILS OOO",
  hamkorlarimiz: "Наши партнеры",
  hamkorlar: "Партнеры",
  bizvamijozlar: "Соглашение между нами и клиентом – это безопасность",
  taminlanadi: "обеспечивается",
  xitoyvaUzb: "Наши команды по всему Китаю и Узбекистану",
  eachteam: "Каждый представитель команды обладает своими сильными сторонами и знаниями. Отношения между нашими клиентами и нашей компанией. Сила команды - результат усилий каждого члена команды.",
  baxtliMijoz: "Станьте одним из счастливы клиентов",
  malumotlarnituldiring:"Заполните данные и присоединяйтесь к рядам счастливых клиентов.",
  ismingiz: "Ваше имя",
  Faoliyatingiz: "Ваша деятельность",
  Phone: "Телефон",
  yukhaqida: "Дополнительная информация о вашем грузе",
  yuborish: "Отправить",
  boshsahifa: "Главная страница",
  bizhaqimizda: "О нас",
  hamkorlarimiz: "Наши партнеры",
  Hodimlar: "Сотрудники",
  Kirish: "Вход",
  boglanish: "Связаться",
};

export default translateRu;
