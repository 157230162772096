// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dots-btn {
    width: 3.5em;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
}

.dots-btn div {
    width: 0.8em;
    height: 0.8em;
    border-radius: 50%;
    /* background-color: #fc2f70; */
    /* background-color: white; */
    animation: fade 0.8s ease-in-out alternate infinite;
}

.dots-btn div:nth-of-type(1) {
    animation-delay: -0.4s;
}

.dots-btn div:nth-of-type(2) {
    animation-delay: -0.2s;
}

@keyframes fade {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/loading/style.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,qBAAqB;IACrB,mBAAmB;IACnB,8BAA8B;AAClC;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,kBAAkB;IAClB,+BAA+B;IAC/B,6BAA6B;IAC7B,mDAAmD;AACvD;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI;QACI,UAAU;IACd;;IAEA;QACI,UAAU;IACd;AACJ","sourcesContent":[".dots-btn {\n    width: 3.5em;\n    display: flex;\n    flex-flow: row nowrap;\n    align-items: center;\n    justify-content: space-between;\n}\n\n.dots-btn div {\n    width: 0.8em;\n    height: 0.8em;\n    border-radius: 50%;\n    /* background-color: #fc2f70; */\n    /* background-color: white; */\n    animation: fade 0.8s ease-in-out alternate infinite;\n}\n\n.dots-btn div:nth-of-type(1) {\n    animation-delay: -0.4s;\n}\n\n.dots-btn div:nth-of-type(2) {\n    animation-delay: -0.2s;\n}\n\n@keyframes fade {\n    from {\n        opacity: 1;\n    }\n\n    to {\n        opacity: 0;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
