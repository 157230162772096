// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/Container (2).png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../assets/Container (3).png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../../assets/Container (4).png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (max-width: 350px) {
  .media-nav {
    width: 260px !important;
  }
}
@media only screen and (max-width: 300px) {
  .media-nav {
    width: 230px !important;
  }
}

.bg-image-1 {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}

.bg-image-2 {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
}

.bg-image-3 {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_2___});
}
`, "",{"version":3,"sources":["webpack://./src/components/navbar/style.css"],"names":[],"mappings":"AAAA;EACE;IACE,uBAAuB;EACzB;AACF;AACA;EACE;IACE,uBAAuB;EACzB;AACF;;AAEA;EACE,yDAA0D;AAC5D;;AAEA;EACE,yDAA0D;AAC5D;;AAEA;EACE,yDAA0D;AAC5D","sourcesContent":["@media only screen and (max-width: 350px) {\n  .media-nav {\n    width: 260px !important;\n  }\n}\n@media only screen and (max-width: 300px) {\n  .media-nav {\n    width: 230px !important;\n  }\n}\n\n.bg-image-1 {\n  background-image: url(\"../../assets/Container\\ \\(2\\).png\");\n}\n\n.bg-image-2 {\n  background-image: url(\"../../assets/Container\\ \\(3\\).png\");\n}\n\n.bg-image-3 {\n  background-image: url(\"../../assets/Container\\ \\(4\\).png\");\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
