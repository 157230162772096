// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.zoom-modal {
    animation: all 1s ease-in-out;
    animation-name: zoom-modal;
  }
  
  @keyframes zoom-modal {
    0% {
      transform: scale(.3);
    }
  
    50% {
      transform: scale(1.1);
    }
  
    100% {
      transform: scale(1);
    }
  }`, "",{"version":3,"sources":["webpack://./src/components/casser/Modal.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;IAC7B,0BAA0B;EAC5B;;EAEA;IACE;MACE,oBAAoB;IACtB;;IAEA;MACE,qBAAqB;IACvB;;IAEA;MACE,mBAAmB;IACrB;EACF","sourcesContent":[".zoom-modal {\n    animation: all 1s ease-in-out;\n    animation-name: zoom-modal;\n  }\n  \n  @keyframes zoom-modal {\n    0% {\n      transform: scale(.3);\n    }\n  \n    50% {\n      transform: scale(1.1);\n    }\n  \n    100% {\n      transform: scale(1);\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
