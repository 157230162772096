import kamaz from "./home/kamaz.png";
import banner from "./home/banner.png";
import kontener from "./home/kontener.png";
import enter from "./home/enter.svg";
// import logo from "./home/logo.png"
import bir from "./icon.png";
import ikki from "./ikki.png";
import footerga from "./home/foterga.png";
import load from "./home/load.svg";
import admin from "./admin.png";
import logo from "./logo.png";
import logo1 from "./Untitled-1logo 1.png";
import container from "./Container.png";
import container1 from "./Container (1).png";
import container2 from "./Container (2).png";
import container3 from "./Container (3).png";
import container4 from "./Container (4).png";
import container5 from "./Container (5).png";
import asset1 from "./Asset 1 1.png";
import asset2 from "./Asset 2 1.png";
import asset3 from "./Asset 3 1.png";
import asset4 from "./YouTube.png";
import asset5 from "./Asset 5 1.png";
import asset6 from "./Asset 6 1.png";
import asset7 from "./Asset 7 1.png";
import asset8 from "./Asset 8 1.png";
import person from "./person.png";
import person2 from "./person2.png";
import person3 from "./person3.png";
import person4 from "./person4.png";
import person5 from "./person5.png";
import person6 from "./person6.png";
import contactImg from "./Contact.png";

export {
  kamaz,
  banner,
  logo1,
  kontener,
  enter,
  logo,
  footerga,
  load,
  bir,
  ikki,
  admin,
  container,
  container1,
  container2,
  container3,
  container4,
  container5,
  asset1,
  asset2,
  asset3,
  asset4,
  asset5,
  asset6,
  asset7,
  asset8,
  person,
  person2,
  person3,
  person4,
  person5,
  person6,
  contactImg,
};
