const translateUz = {
    home: "Xitoy biz bilan bir qadamda joylashgan.",
    info: "Bizning GSR Logistics jamoamiz allaqachon 10 yildan beri mijozlarga sifatli xizmat ko'rsatib kelmoqda.",
    yuklar: "Sizga kerakli yuk turini topamiz.",
    hujjat: "Hujjatlar va savdo jarayonlariga yordam beramiz.",
    kafolat: "Sizning posilkangiz to'liq sug'urta qilingan holda yetib keladi.",
    razmer: "Yuk hajmi",
    ogirlik: "Og'irlik",
    qayerga: "Qayerdan",
    qayerdan: "Qayerga",
    qancha: "Yuk turi",
    m3: "m3",
    kg: "kg",
    qayer: "Yiwu",
    Toshkent: "Toshkent",
    mahsulot: "Uy anjomlari",
    xisoblash: "Yuk hajmini hisoblash",
    komponiya: "Bizning kompaniya haqida",
    mijozlar: "Mijozlarimizning g'alabasi biz uchun",
    muhim: "muhim",
    boglanish: "Bog'lanish",
    xizmat_turi: "Logistika xizmatlari turlari",
    yuk_davlati: "Logistika turi yuk mamlakati va masofasiga qarab farqlanadi.",
    yuk_mashinasi: "Yuk mashinasidan foydalanish",
    xitoydan_yuk: "Xitoydan yuklarni olib kelishning 80% holatida biz yuk mashinalaridan foydalanamiz",
    cargo: "Yuk havosi",
    avia_cargo: "Kichik, juda tez kerak bo'ladigan yuklar Avia Cargo orqali yetkazib beriladi.",
    temir_yul: "Temir yo'l",
    narxi_arzon: "Muddati muhim emas, narx arzonroq tanlanadi.",
    keltirilgan_yuklar: "Keltirilgan yuklar",
    keltirilgan_butun_yuklar: "Jami keltirilgan yuk (yuk/konteyner)",
    kun: "kun",
    muddat: "O'rtacha kirish vaqti",
    mijoz_fikri: "Bizning mijozlarimiz nima deb o'ylashadi",
    gsrlogistik: "GSR Logistics muntazam mijozlari",
    ishonch: "bizning kompaniyamizga bo'lgan ishonch bizning yutug'imizdir.",
    sifatli_xizmat: "“Sifatli xizmat va tezlik”",
    xitoydan_tashqari: "GSR Logistics kompaniyasi Xitoydan tashqari har bir bosqichda bizning foydamizni o'ylaydi, shuning uchun biz ishonamiz",
    erkin: "Ariqulov Erkin",
    detail: "SHVEY DETAILS OOO",
    hamkorlarimiz: "Bizning hamkorlarimiz",
    hamkorlar: "Hamkorlar",
    yutuqlarimiz:"Bizning yutuqlarimiz",
    xojakbar:"Xojiakbar",
    biznes:"Biznes",
    Language:"Til",
    taos:"Iltimos ma'lumotlarni to'ldiring !",
    ObunaBuling:"Obuna bo’ling",
    yukHajmi:"Yuk hajmi 200 kg 3 kub atrofida",
    hamkorlik:"Biz 4 yildan ortiq hamkorlik qilib kelmoqdamiz",
    natija:"10 yil davomida erishgan yutuqlarimizni ko'ring",
    bizvamijozlar: "Biz va mijozlar o'rtasidagi kelishuv xavfsizlikdir",
    taminlanadi: "ta'minlanadi",
    xitoyvaUzb: "Xitoy va O'zbekiston bo'ylab bizning jamoalarimiz",
    eachteam: "Har bir jamoa vakili o'z kuchli tomonlari va bilimlariga ega. Bizning mijozlarimiz va kompaniyamiz o'rtasidagi munosabatlar. Jamoaning kuchi har bir jamoa a'zosi mehnatining natijasidir.",
    baxtliMijoz: "Baxtli mijozlardan biri bo'ling",
    malumotlarnituldiring: "Ma'lumotlarni to'ldiring va baxtli mijozlar qatoriga qo'shiling.",
    ismingiz: "Ismingiz",
    Faoliyatingiz: "Faoliyatingiz",
    Phone: "Telefon",
    yukhaqida: "Yuk haqida qo'shimcha ma'lumot",
    yuborish: "Yuborish",
    // Navbar 
    boshsahifa:"Bosh sahifa",
    bizhaqimizda:"Biz haqimizda",
    hamkorlarimiz:"Hamkorlarimiz",
    Hodimlar:"Hodimlar",
    Kirish:" Kirish",
    boglanish:"Bog'lanish"
  };
  
  export default translateUz;
  